"use client";

import { Controller } from "react-hook-form";

import { InputDropdownProps } from "./InputDropdown.props";
import classNames from "classnames";
import Dropdown from "react-dropdown";
import { ChevronDown, ChevronUp } from "react-feather";

export const InputDropdown: React.FC<InputDropdownProps> = ({
	title,
	name,
	// children,
	defaultValue,
	onChange,
	errors,
	placeholder,
	options,
	secondary = false,
	tertiary = false,
}) => {
	// if (children) {
	// 	return children;
	// }
	return (
		//@ts-ignore
		<Controller
			name={name}
			defaultValue={defaultValue}
			key={name}
			render={(form) => {
				return (
					<div
						className={classNames("dropdown", {
							dropdown__secondary: secondary,
							dropdown__tertiary: tertiary,
						})}
					>
						<div className={"dropdown__label"}>
							<label className={"dropdown__title"}>
								<span>{title}</span>

								<span className={"dropdown__error"}>
									{form.formState.errors?.[name!]?.message
										? (form.formState.errors?.[name!]?.message as JSX.Element)
										: ""}
								</span>
							</label>
						</div>
						<Dropdown
							{...form.field}
							options={options}
							baseClassName="dropdown__base"
							onChange={(e) => {
								onChange(e.value);
							}}
							menuClassName="dropdown__menu"
							controlClassName="dropdown__control"
							arrowClassName="dropdown__arrow"
							placeholderClassName="dropdown__placeholder"
							className="dropdown__dropdown"
							arrowOpen={
								<span className="dropdown__arrow__open">
									<ChevronUp />
								</span>
							}
							arrowClosed={
								<span className="dropdown__arrow__closed">
									<ChevronDown />
								</span>
							}
						/>
					</div>
				);
			}}
		/>
	);
};
