import { PayloadContentBlock } from "../Payload";

interface ContentBlockProps {
	block: any;
}

export const ContentBlock = ({ block }: ContentBlockProps) => (
	<div className="content-block">
		<PayloadContentBlock {...block} key={`content-block`} />
	</div>
);
