"use client";

import { Controller } from "react-hook-form";

import { InputSliderProps } from "./InputSlider.props";
import classNames from "classnames";

export const InputSlider: React.FC<InputSliderProps> = ({
	title,
	name,
	// children,
	defaultValue,
	onChange,
	errors,
	placeholder,
	secondary = false,
	tertiary = false,
}) => {
	// if (children) {
	// 	return children;
	// }
	return (
		//@ts-ignore
		<Controller
			name={name}
			defaultValue={defaultValue}
			key={name}
			render={(form) => {
				return (
					<div
						className={classNames("slider", {
							slider__secondary: secondary,
							slider__tertiary: tertiary,
						})}
					>
						<div className={"slider__label"}>
							<label className={"slider__title"}>
								<span>{title}</span>

								<span className={"slider__error"}>
									{form.formState.errors?.[name!]?.message
										? (form.formState.errors?.[name!]?.message as JSX.Element)
										: ""}
								</span>
							</label>
						</div>

						<input
							className="slider"
							type="range"
							min="0"
							max="1"
							step={0.01}
							value={form.field.value}
							onChange={form.field.onChange}
						/>
					</div>
				);
			}}
		/>
	);
};
