"use client";

import { useRequestAccountRemoval } from "@/hooks/account";
import { useCheckAuthAndRedirect } from "hooks/authentication";
import { useState } from "react";
import { Logout } from "ui/components/authentication/Logout";
import { Form } from "ui/components/form";
import { Subtitle } from "ui/components/typography";
import Yup from "ui/utils/yupUtils";

export const SettingsComponent = () => {
	useCheckAuthAndRedirect({
		location: "settings",
		redirect: "settings",
		login: "/login",
	});

	const { trigger: requestAccountDeletion } = useRequestAccountRemoval();

	const [reasonType, setReasonType] = useState("I don't like the app");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const deleteReasons = [
		{ label: "I have multiple accounts", value: "multiple_accounts" },
		{ label: "I no longer use the app", value: "no_longer_use" },
		{ label: "I have privacy concerns", value: "privacy_concerns" },
		{ label: "I have another account", value: "another_account" },
		{ label: "I use a different app", value: "different_app" },
		{ label: "I have a different reason", value: "different_reason" },
		{ label: "I don't like the app", value: "dont_like" },
		{ label: "Other", value: "other" },
	];

	const onDeleteAccount = ({ reason }: any) => {
		const type = deleteReasons.find((reason) => reason.label === reasonType)
			?.value!;

		requestAccountDeletion({ reason: reason, reasonType: type } as any, {
			onSuccess: (data) => {
				if (data.code === 201) {
					setSuccess("Your request has been sent. We will contact you soon.");
					setError("");
				} else {
					setSuccess("");
					setError("Something went wrong");
				}
			},
			onError: (error) => {
				setSuccess("");
				setError("Something went wrong");
			},
		});
	};

	return (
		<div className="column align-center">
			<div className="settings-section">
				<h2>Request account deletion</h2>

				<Form.Form
					onSubmit={onDeleteAccount}
					buttonId="delete-account"
					buttonText="Delete account"
					initialValues={{ description: "" }}
					isLoading={false}
					validationSchema={Yup.object().shape({
						description: Yup.string(),
					})}
				>
					<Form.Input.Dropdown
						name="reasonType"
						title="Reason for account deletion"
						onChange={(value) => setReasonType(value)}
						options={deleteReasons.map((reason, index) => reason.label)}
					/>
					<Form.Input.Text name="reason" title="Additional information" />
				</Form.Form>
				<Subtitle>This can not be undone!</Subtitle>
				{success && <p className="success-text">{success}</p>}
				{error && <p className="error-text">{error}</p>}
			</div>

			<Logout />
		</div>
	);
};
