"use client";

import React, { useRef } from "react";
import { Controller } from "react-hook-form";

//@ts-ignore
import { InputFileProps } from "./InputFile.props";
import classNames from "classnames";
import { File } from "react-feather";
import { ButtonText } from "../../../typography";
import { Button } from "../../../buttons/Button";

export const InputFile = ({
	children,
	name,
	defaultValue,
	fileIcon,
	fileMessage,
	title,
	onChange,
	errors,
	multiple = false,
	placeholder,
	centered = false,
}: InputFileProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	if (children) {
		return children;
	}

	return (
		//@ts-ignore
		<Controller
			name={name}
			defaultValue={defaultValue}
			key={name}
			render={(form) => {
				return (
					<div
						className={classNames("input", {
							input__centered: centered,
							// input__secondary: secondary,
							// input__tertiary: tertiary,
						})}
					>
						<div className={"input__label"}>
							<label className={"input__title"}>
								<span>{title}</span>

								<span className={"input__error"}>
									{form.formState.errors?.[name!]?.message
										? (form.formState.errors?.[name!]?.message as JSX.Element)
										: ""}
								</span>
							</label>
						</div>

						<input
							{...form.field}
							ref={inputRef}
							type="file"
							onChange={onChange}
							multiple={multiple}
							style={{ display: "none" }}
						/>

						<Button
							id="upload-image-button"
							onPress={() => {
								inputRef ? inputRef.current?.click() : null;
							}}
						>
							Select media
						</Button>
						{/* <div>
							{inputRef?.current?.files &&
								inputRef?.current?.files?.length > 0 &&
								// map over files and display them here
								Array.from(inputRef?.current?.files?.length).map(
									(file: any, index: number) => {
										return (
											<span>{inputRef?.current?.files?.[index].name}</span>
										);
									}
								)}
						</div> */}
					</div>
				);
			}}
		/>
	);
};
