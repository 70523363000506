"use client";

import { Controller } from "react-hook-form";

import { InputDropdownProps } from "./InputDateTime.props";
import classNames from "classnames";
import DateTimePicker from "react-datetime-picker";
import { useEffect, useState } from "react";
import { Calendar } from "react-feather";

export const InputDateTime: React.FC<InputDropdownProps> = ({
	title,
	name,
	// children,
	defaultValue,
	value,
	onChange,
	errors,
	placeholder,
	secondary = false,
	tertiary = false,
}) => {
	const [showCalendar, setShowCalendar] = useState(false);

	return (
		//@ts-ignore
		<Controller
			name={name}
			defaultValue={defaultValue}
			key={name}
			render={(form) => {
				return (
					<div
						className={classNames("calendar", {
							calendar__secondary: secondary,
							calendar__tertiary: tertiary,
						})}
					>
						<div className={"calendar__label"}>
							<label className={"calendar__title"}>
								<span>{title}</span>

								<span className={"dropdown__error"}>
									{form.formState.errors?.[name!]?.message
										? (form.formState.errors?.[name!]?.message as JSX.Element)
										: ""}
								</span>
							</label>
						</div>

						<DateTimePicker
							onChange={(value: any) => onChange(value)}
							format="dd/MM/y HH:mm"
							className="datepicker"
							disableClock
							value={value}
							onCalendarOpen={() => setShowCalendar(true)}
							onCalendarClose={() => setShowCalendar(false)}
							calendarClassName={classNames("datepicker__calendar", {
								hidden: !showCalendar,
							})}
							tileClassName={classNames("datepicker__tile", {
								// hidden: !showCalendar,
							})}
							dayPlaceholder="26"
							monthPlaceholder="08"
							yearPlaceholder="2024"
							hourPlaceholder="18"
							minutePlaceholder="30"
							clearIcon={null}
							calendarIcon={null}

							// calendarIcon={<Calendar />}
						/>
					</div>
				);
			}}
		/>
	);
};
