"use client";

import { BlogsComponent } from "./BlogsComponent";

export default function BlogsPage(props: any) {
	return (
		<div className="container">
			<BlogsComponent {...props} />
		</div>
	);
}
