"use client";
import { useForgotPassword } from "@/hooks/authentication";
import { useState } from "react";
import { Form } from "ui/components/form";
import Yup from "ui/utils/yupUtils";

export const ForgotPasswordForm = () => {
	const { trigger: forgotPassword, isMutating } = useForgotPassword();
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");

	const onSubmit = ({ email }: { email: string }) => {
		forgotPassword(
			{
				email,
			} as any,
			{
				onSuccess: (response: any) => {
					if (response?.code === 400) {
						setError(response?.message);
						setSuccess("");
					} else {
						setSuccess(response?.message);
						setError("");
					}
				},
				onError: (error: any) => {
					setError(
						"Something went wrong. Try again later or try to request a new reset"
					);
				},
			}
		);
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.required("Password is required")
			.email("Invalid email address"),
	});

	return (
		<Form.Form
			onSubmit={onSubmit}
			buttonId="forgot-password-button"
			buttonText="Request password reset"
			initialValues={{ email: "" }}
			validationSchema={schema}
			isLoading={isMutating}
		>
			<Form.Input.Text
				name="email"
				placeholder="Email"
				// onChange={(e) => setPassword(e.target.value)}
			/>

			{success && <p>{success}</p>}
			{error && <p className="error">{error}</p>}
		</Form.Form>
	);
};
