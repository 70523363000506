/* eslint-disable react-hooks/rules-of-hooks */
"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import {
	Children,
	cloneElement,
	isValidElement,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "../../buttons/Button";
import type { FormProps } from "./Form.props";
import classNames from "classnames";

export const Form: React.FC<FormProps> = ({
	onSubmit,
	onSkip,
	skipText,
	validationSchema,
	initialValues,
	buttonText,
	buttonId,
	isLoading,
	hasExternalSubmit,
	dark = false,
	hideSubmit = false,
	buttonDisabled = false,
	secondary = false,
	tertiary = false,
	inverted = false,
	children,
	externalValues,
	resetExternalValues,
}) => {
	const formMethods = useForm<any>({
		defaultValues: initialValues,
		resolver: yupResolver(validationSchema),
		mode: "onTouched",
		reValidateMode: "onBlur",
	});
	useEffect(() => {
		if (externalValues) {
			// find changed values
			const changedValues: any = {};
			Object.keys(externalValues).forEach((key) => {
				if (
					externalValues[key]?.toString() !==
					formMethods.getValues()[key]?.toString()
				) {
					changedValues[key] = externalValues[key];
				}
			});
			formMethods.reset({
				...formMethods.getValues(),
				...changedValues,
			});
			resetExternalValues && resetExternalValues();
		}
	}, [externalValues, formMethods, resetExternalValues]);

	let submitHandler: any = null;

	if (hasExternalSubmit) {
		submitHandler = useCallback(() => {
			if (onSubmit) {
				onSubmit(formMethods.getValues());
			}
		}, [formMethods, onSubmit]);
	} else {
		submitHandler = useMemo(() => {
			if (onSubmit) {
				return formMethods.handleSubmit(onSubmit);
			}
		}, [formMethods, onSubmit]);
	}

	return (
		<div
			className={classNames("form", {
				form__dark: dark,
			})}
		>
			<FormProvider {...formMethods}>
				{Children.map(children, (child: any) => {
					if (isValidElement(child)) {
						return cloneElement(child, {
							...formMethods,
							...{ secondary, tertiary },
						});
					}
					return child;
				})}

				{/* <div className="button-container"> */}
				{!hideSubmit && (
					<Button
						disabled={buttonDisabled || isLoading}
						id={buttonId}
						inverted={inverted}
						onPress={submitHandler}
						secondary={secondary}
						size="wide"
						tertiary={tertiary}
					>
						{isLoading ? "Loading..." : `${buttonText}`}
					</Button>
				)}
				{/* </div> */}

				{/* <div className="button-container"> */}
				{!!onSkip && (
					<Button
						disabled={buttonDisabled || isLoading}
						id="skip"
						inverted
						onPress={onSkip}
						size="wide"
					>
						{isLoading ? "Loading..." : skipText ? skipText : "Skip"}
					</Button>
				)}
				{/* </div> */}
			</FormProvider>
		</div>
	);
};
