"use client";

import { useState } from "react";
import Yup from "../../utils/yupUtils";
import { Form } from "../form";
import { useRegister } from "hooks/authentication";
import { useDispatch } from "react-redux";
import { login } from "redux-store/reducers";

export const RegisterField = ({ toggle }: { toggle: () => void }) => {
	const { trigger: register } = useRegister();
	const [conditionsError, setConditionsError] = useState(false);
	const [passwordError, setPasswordError] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [success, setSuccess] = useState(false);

	const dispatch = useDispatch();

	const onSubmit = (values: any) => {
		if (!values.acceptConditions) {
			return setConditionsError(true);
		}
		setConditionsError(false);

		if (!values.password) return setPasswordError("You need a password");

		if (values.password !== values.passwordRepeated) {
			return setPasswordError("Passwords must match");
		} else if (values.username.length < 3) {
			setUsernameError("Username must be at least 3 characters");
			return setPasswordError("");
		} else if (
			!values.email ||
			!values.email.includes("@") ||
			!values.email.includes(".")
		) {
			setEmailError("You must enter a valid email");
			return setUsernameError("");
		}
		setEmailError("");

		register(values, {
			onSuccess: async (response: any) => {
				if (response?.token && response?.user) {
					dispatch(
						login({
							user: response.user,
							credentials: response.token,
							profile: response.user?.profile,
						})
					);
					setSuccess(true);
				} else {
					if (response?.message === "Email already taken")
						return setEmailError("Email already taken");
					else if (response?.message === "Username already taken")
						return setUsernameError("Username already taken");
				}
			},
		});
	};

	const schema = Yup.object().shape({
		email: Yup.string().required().email(),
		username: Yup.string()
			.required()
			.test(
				"is-username",
				"Username must be at least 3 characters",
				(value?: string) => {
					if (!value) return false;
					return value?.length >= 3;
				}
			)
			.matches(
				/^[a-zA-Z0-9_]*$/,
				"Username can only contain letters, numbers and underscores"
			),
		password: Yup.string().required(),
		passwordRepeated: Yup.string().required(),
		acceptConditions: Yup.boolean()
			.required()
			.oneOf([true], "You must accept the terms and conditions"),
	});

	return (
		<div className="container">
			<Form.Form
				buttonId="register-button"
				buttonText="Register"
				onSubmit={onSubmit}
				initialValues={{
					email: "",
					username: "",
					password: "",
					passwordRepeated: "",
				}}
				validationSchema={schema}
				isLoading={false}
				hasExternalSubmit
			>
				{success && <p className="success-text">Successfully registered!</p>}
				{emailError && <p className="error-text">{emailError}</p>}
				{usernameError && <p className="error-text">{usernameError}</p>}
				{passwordError && <p className="error-text">{passwordError}</p>}
				{conditionsError && (
					<p className="error-text">You must accept the terms and conditions</p>
				)}
				<Form.Input.Text name="email" title="Email" />
				<Form.Input.Text name="username" title="Username" />
				<Form.Input.Text name="password" title="Password" password />
				<Form.Input.Text
					name="passwordRepeated"
					title="Password again"
					password
				/>
				<Form.Input.Checkbox
					name="acceptConditions"
					title="Accept terms and conditions"
				/>
			</Form.Form>

			<div className="my-2">
				<span>By registering you accept our </span>
				<a target="_blank" href="/terms-and-conditions">
					<span className="link">Terms and Conditions</span>
				</a>
				<span> and our </span>
				<a target="_blank" href="/privacy-policy">
					<span className="link">Privacy Policy</span>
				</a>
			</div>

			<a onClick={toggle}>Already have an account? Login here.</a>
		</div>
	);
};
