"use client";

export default function TermsAndConditionsPage() {
	return (
		<div className="container mt-8">
			<div className=" all-text">
				<h1>Terms and Conditions</h1>
				<p>
					Terms of Service for ApolloFit Welcome to ApolloFit! These Terms of
					Service &quot;Terms&quot; govern your use of our gym/social media app
					&quot;ApolloFit&quot;. By accessing or using ApolloFit, you agree to
					be bound by these Terms.
				</p>
				<h5>1. Basic Terms</h5>
				<p>
					You must be at least 13 years old to use ApolloFit. You are
					responsible for maintaining the security of your account and password.
					You must not abuse, harass, threaten, or impersonate other users.
				</p>
				<h5>2. Content on ApolloFit</h5>
				<p>
					You retain ownership of the content you post on ApolloFit. By posting
					content, you grant us a non-exclusive, royalty-free, transferable,
					sublicensable license to use, reproduce, modify, adapt, publish,
					translate, create derivative works from, distribute, and display such
					content.
				</p>
				<h5>3. Prohibited Conduct</h5>
				<p>
					You agree not to engage in any conduct that violates these Terms or
					applicable laws. This includes, but is not limited to, spamming,
					distributing malware, or engaging in any activity that disrupts or
					interferes with the functioning of ApolloFit. We reserve the right to
					suspend or terminate your account if you violate these Terms.
				</p>
				<h5>4. Content Creation Guidelines</h5>
				<p>
					Users are prohibited from creating, uploading, or sharing any content
					that contains or promotes:
					<ul>
						<li> - Nudity, sexually explicit material, or pornography.</li>
						<li>
							{" "}
							- Hate speech, racism, discrimination, or any form of harassment.
						</li>
						<li>
							{" "}
							- Sexism, misogyny, or any content that promotes violence or abuse
							against individuals or groups based on gender, sexual orientation,
							race, ethnicity, religion, or other characteristics.
						</li>
						<li>
							{" "}
							- Violence, gore, graphic images, or content that glorifies or
							promotes self-harm, suicide, or harm to others.
						</li>
						<li>
							{" "}
							- Illegal activities, including but not limited to drug
							trafficking, weapons trade, or any other criminal activity.
						</li>
						<li>
							{" "}
							- Misleading or fraudulent content, including scams, phishing
							attempts, or deceptive practices.
						</li>
						<li>
							{" "}
							- Content that infringes upon the intellectual property rights of
							others, including copyrights, trademarks, patents, or trade
							secrets.
						</li>
						<li>
							{" "}
							- Any other content that violates applicable laws, regulations, or
							our community standards.
						</li>
					</ul>
				</p>
				<h5>5. Intellectual Property</h5>
				<p>
					ApolloFit and its original content, features, and functionality are
					owned by us and are protected by international copyright, trademark,
					patent, trade secret, and other intellectual property or proprietary
					rights laws.
				</p>
				<h5>6. Termination</h5>
				<p>
					We reserve the right to terminate or suspend your access to ApolloFit
					at any time for any reason without notice.
				</p>
				<h5>7. Limitation of Liability</h5>
				<p>
					In no event shall ApolloFit, its creators, or affiliates be liable for
					any indirect, incidental, special, consequential, or punitive damages,
					including but not limited to, loss of profits, data, or goodwill.
				</p>
				<h5>8. Governing Law</h5>
				<p>
					These Terms shall be governed by and construed in accordance with the
					laws of the Netherlands, without regard to its conflict of law
					provisions.
				</p>
				<h5>9. Changes to These Terms</h5>
				<p>
					We reserve the right to modify or replace these Terms at any time. If
					a revision is material, we will provide at least 30 days notice prior
					to any new terms taking effect.
				</p>
				<h5>10. Contact Us</h5>
				<p>
					If you have any questions about these Terms, please contact us at
					legal@apollofit.app. Thank you for using ApolloFit! We hope you enjoy
					your experience.
				</p>
			</div>
		</div>
	);
}
