import { ReactNode } from "react";
import { Colors } from "../../theme/Colors";

interface BodyCaptionProps {
	children: ReactNode;
	bold?: boolean;
	centered?: boolean;
	style?: any;
}

export function BodyCaption({
	children,
	bold,
	centered,
	style,
	...props
}: unknown & BodyCaptionProps) {
	return (
		<span
			className="body-caption"
			{...props}
			style={{
				color: Colors.white,
				fontWeight: bold ? "bold" : "500",
				textAlign: centered ? "center" : "unset",
				...style,
			}}
		>
			{/* @ts-ignore */}
			{children}
		</span>
	);
}
