import useSWRMutation from "swr/mutation";

interface ResetPasswordProps {
	arg: {
		token: string;
		password: string;
	};
}

const resetPassword = async (_: string, { arg }: ResetPasswordProps) => {
	const { token, password } = arg;
	const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || "";

	const rawResponse = await fetch(`${BACKEND_URL}/users/reset-password`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			token,
			password,
		}),
	});
	const response = await rawResponse.json();

	return {
		code: response.code,
		message: response.message,
		data: response?.data,
	};
};

export const useResetPassword = () => {
	return useSWRMutation("useResetPassword", (_, arg) => resetPassword(_, arg));
};
