/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";

import type { Media as MediaType } from "@/types";

import { Image } from "./Image";
import { Props } from "./types";
import { Video } from "./Video";

export const Media: React.FC<Props> = (props) => {
	const { className, resource, htmlElement = "div" } = props;

	const isVideo =
		typeof resource !== "string" && resource?.mimeType?.includes("video");
	const Tag = (htmlElement as any) || Fragment;

	return (
		<Tag
			{...(htmlElement !== null
				? {
						className,
				  }
				: {})}
		>
			{isVideo ? <Video {...props} /> : <Image {...props} />}
		</Tag>
	);
};
