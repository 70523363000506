import { ArchiveBlock } from "./_blocks/ArchiveBlock";
import { CallToActionBlock } from "./_blocks/CallToAction";
import { ContentBlock } from "./_blocks/Content";
import { MediaBlock } from "./_blocks/MediaBlock";
import { RelatedPosts } from "./_blocks/RelatedPosts";

import { HighImpactHero } from "./_heros/HighImpact";
import { LowImpactHero } from "./_heros/LowImpact";
import { MediumImpactHero } from "./_heros/MediumImpact";
import { PostHero } from "./_heros/PostHero";
import { ProjectHero } from "./_heros/ProjectHero";

export {
	ArchiveBlock as PayloadArchiveBlock,
	CallToActionBlock as PayloadCallToActionBlock,
	ContentBlock as PayloadContentBlock,
	MediaBlock as PayloadMediaBlock,
	RelatedPosts as PayloadRelatedPosts,
	HighImpactHero as PayloadHighImpactHero,
	LowImpactHero as PayloadLowImpactHero,
	MediumImpactHero as PayloadMediumImpactHero,
	PostHero as PayloadPostHero,
	ProjectHero as PayloadProjectHero,
};
