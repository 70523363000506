import { useDispatch } from "react-redux";
import { logout } from "redux-store/reducers";
import { Button } from "../buttons/Button";

export const Logout = () => {
	const dispatch = useDispatch();

	const onLogout = () => {
		dispatch(logout({}));
		window.location.replace("/login");
	};

	return (
		<Button inverted onPress={onLogout}>
			Logout
		</Button>
	);
};
