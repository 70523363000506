import Image from "next/image";
import { ChevronRight } from "react-feather";

interface CardProps {
	image: {
		url: string;
		alt: string;
	}; // URL
	title: string;
	description: string;
	link: string;
}

export const Card = ({ image, title, description, link }: CardProps) => {
	return (
		<a href={link}>
			<div className="card">
				<div className="card__image">
					<Image
						src={encodeURI(image.url.replace(":7777", ""))}
						alt={image.alt}
						layout="fill"
						objectFit="cover"
					/>
				</div>

				<div className="card__content">
					<div className="card__text">
						<h3>{title}</h3>
						<p>{description}</p>
					</div>

					<div className="row card__read-more">
						<ChevronRight />
						Read more
					</div>
				</div>
			</div>
		</a>
	);
};
