import * as Yup from "yup";

// import { PASSWORD, PHONE } from '@Constants/regexp';
export const PASSWORD =
	/(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+}{&;:;'?/&.,<>])(?!.*\s).*$/g; // One lowercase, one uppercase, one digit, one special char, length 8 - 24
export const PHONE = /^(((\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;

export interface AsyncValidationOptions {
	ignore?: string | string[];
	schema?: Yup.AnySchema;
}

export interface AsyncValidationCall {
	isValid: boolean;
	reason?: string;
}

export const getAsyncValidation = (
	call: (value: string) => Promise<AsyncValidationCall>,
	options: AsyncValidationOptions
) => {
	return Yup.string().test({
		name: "async",
		test: async function (value: any) {
			const { path, createError } = this;

			// Validate custom schema
			if (options.schema) {
				try {
					await options.schema.validate(value);
				} catch ({ message }: any) {
					return createError({
						path,
						message: String(message),
					});
				}
			}

			// Validate with async call
			const response = await call(value);

			if (response.isValid) {
				return true;
			}

			const message = response.reason
				? `DOMAIN.YUP.string.async.${path}.${response.reason.toLowerCase()}`
				: (`DOMAIN.YUP.string.async.${path}` as any);

			return createError({
				path,
				message: message,
			});
		},
	});
};

Yup.addMethod(Yup.string, "phone", () => {
	return Yup.string().test("phone", "", function (value: any) {
		const { path, createError } = <any>this;

		return (
			(value && value.match(PHONE)) ||
			createError({ path, message: "DOMAIN.YUP.string.phone" })
		);
	});
});

Yup.addMethod(Yup.string, "password", (ref: string) => {
	// Check if password is equal to the reference
	if (ref) {
		return Yup.string().test("password", "", function (value: any) {
			const { path, createError } = <any>this;

			return (
				this.parent[ref] === value ||
				createError({
					path,
					message: "DOMAIN.YUP.string.password_match",
				})
			);
		});
	}

	// Check if password is in correct format
	return Yup.string().test("password", "", function (value: any) {
		const { path, createError } = <any>this;

		return (
			(value && value.match(PASSWORD)) ||
			createError({ path, message: "DOMAIN.YUP.string.password" })
		);
	});
});

export default Yup;
