"use client";

import { FlexiblePageComponent } from "./FlexiblePage";

export default function FlexiblePage(props: any) {
	return (
		<div className="container">
			<FlexiblePageComponent {...props} />
		</div>
	);
}
