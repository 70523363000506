// Light/dark mode colors are filled in in app/src/providers/ThemeProvider.tsx
const primary = "#3544DC";
const primaryLighter = "#5764E2";
const primaryLightest = "#7F89EA";
const primaryDarker = "#1527D5";
const primaryDarkest = "#0D1B9E";
const secondary = "#6F2DDB";
const secondaryLighter = "#A379E9";
const secondaryLightest = "#8750E1";
const secondaryDarker = "#590ED4";
const secondaryDarkest = "#41099C";
const tertiary = "#C11ED8";
const tertiaryLighter = "#D970E8";
const tertiaryLightest = "#CC44DF";
const tertiaryDarker = "#B803D1";
const tertiaryDarkest = "#850297";

export const Colors = {
	// _primary: '#7dc5a8',
	primary,
	primaryLighter,
	primaryLightest,
	primaryDarker,
	primaryDarkest,
	secondary,
	secondaryLighter,
	secondaryLightest,
	secondaryDarker,
	secondaryDarkest,
	tertiary,
	tertiaryLighter,
	tertiaryLightest,
	tertiaryDarker,
	tertiaryDarkest,
	white: "#ffffff",
	black: "#000000",
	success: "#5CB85C",
	warning: "#f0ad4e",
	failure: "#dc3544",
	red: "#F5515F",
	blue: "#007AFF",
	100: "#f8f8f8",
	200: "#e6e6e6",
	300: "#ccc",
	400: "#a8a7a5",
	500: "#555",
	600: "#3d3d3d",
	700: "#28282b",
	800: "#1f1f1f",
	900: "#000000",
	gradients: {
		primary: ["#CD4464", "#7B8AF1"],
		secondary: ["#CD4464", "#9A1A3A"],
		tertiary: ["#a4c6b8", "#5e435d"],
		buttons: {
			primary: [primary, primary],
			secondary: [secondary, secondary],
			tertiary: [tertiary, tertiary],
			// primary: ['#8E2EF7', '#61DEC7'],
			// secondary: ['#EF33F2', '#3544DC'],
			// tertiary: ['#8E2EF7', '#F5515F'],
		},
		profile: ["#00B2FF", "#EB00FF"],
		dark: ["#131313", "#666"],
	},
};
