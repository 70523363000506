import useSWRMutation from "swr/mutation";
import { store } from "redux-store/store";

interface RequestAccountRemovalProps {
	arg: {
		reasonType: string;
		reason: string;
	};
}

const requestAccountRemoval = async (
	_: string,
	{ arg }: RequestAccountRemovalProps
) => {
	const { reasonType, reason } = arg;
	const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || "";

	const TOKEN = store.getState()?.auth?.credentials;

	const rawResponse = await fetch(`${BACKEND_URL}/settings/delete-account`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${TOKEN}`,
		},
		body: JSON.stringify({ reasonType, reason }),
	});
	const response = await rawResponse.json();

	return {
		code: response.code,
		message: response.message,
		data: response?.data,
	};
};

export const useRequestAccountRemoval = () => {
	return useSWRMutation("useRequestAccountRemoval", (_, arg) =>
		requestAccountRemoval(_, arg)
	);
};
