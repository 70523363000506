"use client";

import { AuthenticationComponent } from "ui/components/authentication";

export default function LoginPage() {
	return (
		<div className="page-content-centered">
			<h1>Register</h1>
			<AuthenticationComponent
				name="Apollo"
				redirectLocation="settings"
				screen="register"
			/>
		</div>
	);
}
