"use client";

import React from "react";
import { Controller } from "react-hook-form";

//@ts-ignore
import styles from "./InputTextArea.module.scss";
import { InputTextAreaProps } from "./InputTextArea.props";

export const InputTextArea = (props: InputTextAreaProps) => {
	if (props.children) {
		return props.children;
	}

	return (
		//@ts-ignore
		<Controller
			name={props.name}
			defaultValue={props.defaultValue}
			key={props.name}
			render={(form) => {
				return (
					<div className={styles.TextArea}>
						<div className={styles.Label}>
							<label className={styles.Title}>
								{props.title}

								<span className={styles.isError}>
									{form.formState.errors.content?.message
										? (form.formState.errors.content?.message as JSX.Element)
										: ((<></>) as JSX.Element)}
								</span>
							</label>
						</div>
						<textarea {...form.field} />
					</div>
				);
			}}
		/>
	);
};
