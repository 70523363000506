"use client";
import { useResetPassword } from "@/hooks/authentication";
import { useState } from "react";
import { Form } from "ui/components/form";
import Yup from "ui/utils/yupUtils";

export const ResetPasswordForm = ({ token }: { token: string }) => {
	const { trigger: resetPassword, isMutating } = useResetPassword();
	const [success, setSuccess] = useState("");
	const [error, setError] = useState("");

	const onSubmit = ({ password }: { password: string }) => {
		resetPassword(
			{
				token,
				password,
			} as any,
			{
				onSuccess: (response: any) => {
					if (response?.code === 400) {
						setError(response?.message);
						setSuccess("");
					} else {
						setSuccess(response?.message);
						setError("");
					}
				},
				onError: (error: any) => {
					setError(
						"Something went wrong. Try again later or try to request a new reset"
					);
				},
			}
		);
	};

	const schema = Yup.object().shape({
		password: Yup.string()
			.required("Password is required")
			.min(8, "Password must be at least 8 characters"),
		confirmPassword: Yup.string()
			.required("Confirm Password is required")
			.oneOf([Yup.ref("password")], "Passwords must match"),
	});

	return (
		<Form.Form
			onSubmit={onSubmit}
			buttonId="reset-password-button"
			buttonText="Reset password"
			initialValues={{ password: "", confirmPassword: "" }}
			validationSchema={schema}
			isLoading={isMutating}
		>
			<Form.Input.Text
				name="password"
				password
				placeholder="Password"
				// onChange={(e) => setPassword(e.target.value)}
			/>
			<Form.Input.Text
				name="confirmPassword"
				password
				placeholder="Confirm password"
				// onChange={(e) => setPassword(e.target.value)}
			/>

			{success && <p>{success}</p>}
			{error && <p className="error">{error}</p>}
		</Form.Form>
	);
};
