import { ReactNode } from "react";
import { Colors } from "../../theme/Colors";

interface SubtitleProps {
	children: ReactNode | JSX.Element | string;
	bold?: boolean;
	centered?: boolean;
}

export const Subtitle = ({
	children,
	bold,
	centered,
	...props
}: SubtitleProps) => {
	return (
		<span
			className="subtitle"
			{...props}
			style={{
				color: Colors.white,
				fontWeight: bold ? "bold" : "500",
				// textAlign: centered ? "center" : "auto",
			}}
		>
			{/* @ts-ignore */}
			{children}
		</span>
	);
};
