import { ReactNode } from "react";
import { Colors } from "../../theme/Colors";

interface BodyTextProps {
	children: ReactNode | JSX.Element | string;
	bold?: boolean;
	centered?: boolean;
	small?: boolean;
}

export const ButtonText = ({
	children,
	bold,
	centered,
	small = false,
	...props
}: BodyTextProps) => {
	return (
		<span
			className="button-text"
			{...props}
			style={{
				color: Colors.white,
				fontWeight: bold ? "bold" : "500",
				fontSize: small ? 10 : 14,
				// textAlign: centered ? "center" : "auto",
			}}
		>
			{/* @ts-ignore */}
			{children}
		</span>
	);
};
