import { Award, Users } from "react-feather";
import { UniqueSellingPoint } from "./UniqueSellingPoint";
import { Ref } from "react";

export const UniqueSellingPoints = ({
	divRef,
}: {
	divRef?: Ref<HTMLDivElement | null>;
}) => {
	const usps = [
		{
			title: "Track your progress",
			description:
				"Keep track of your workouts and see how you're improving over time. Create or generate workouts, track your workouts, see your personal records, and way more.",
			image: "/images/mocks/workouts_mock.png",
			side: "right",
		},
		{
			title: "Keep in touch with your friends",
			description:
				"Stay connected with your friends and see what they're up to. Keep each other accountable and motivated. Never workout alone again.",
			image: "/images/mocks/feed_mock.png",
			side: "left",
		},
		{
			title: "And the best thing?",
			description:
				"It's completely free. No forced premium subscription, no hidden costs. Just you and your friends.",
			image: "/images/mocks/home_mock.png",
			side: "right",
		},
	];

	return (
		<div ref={divRef} className="unique-selling-points">
			<div className="unique-selling-points__items">
				{usps.map((usp, index) => (
					<UniqueSellingPoint key={index} {...usp} />
				))}
			</div>
		</div>
	);
};
