"use client";

export default function PrivacyPolicyPage() {
	return (
		<div className="container mt-8">
			<div className=" all-text">
				<h1>Privacy Policy</h1>

				<p>
					Welcome to ApolloFit! Your privacy and security are very important to
					us. This Privacy Policy outlines how we collect, use, and protect your
					personal information when you use our gym/social media app
					&quot;ApolloFit&quot;.
				</p>

				<h5>1. Information We Collect</h5>

				<ul>
					<li>
						- Personal Information: Such as your name, email address, and
						profile information.
					</li>
					<li>
						- Usage Information: Including your interactions with the app,
						preferences, and device information.
					</li>
					<li>
						- Analytical Data: We track data for analytical purposes to
						continuously improve the app and services.
					</li>
				</ul>

				<h5>2. How We Use Your Information</h5>

				<ul>
					<li>- Provide and personalize ApolloFit&apos;s services.</li>
					<li>- Improve and develop new features.</li>
					<li>
						- Analyze usage trends and preferences to enhance user experience.
					</li>
					<li>
						- Track crash reports and app performance for troubleshooting and
						improvement.
					</li>
					<li>
						- Enrich our AI model to improve personalized experiences for users.
					</li>
				</ul>

				<h5>3. Sharing of Information</h5>

				<p>
					We do not sell your personal information to third parties. Information
					may be shared with third-party service providers who assist us in
					delivering and improving our services, subject to confidentiality
					agreements.
				</p>

				<h5>4. Data Retention</h5>

				<p>
					We retain your information for as long as necessary to fulfill the
					purposes outlined in this Privacy Policy, unless otherwise required by
					law.
				</p>

				<h5>5. Security Measures</h5>

				<p>
					We employ industry-standard security measures to protect your personal
					information from unauthorized access, alteration, or disclosure.
				</p>

				<h5>6. Your Choices</h5>

				<p>
					You have the right to access, correct, or delete your personal
					information stored in ApolloFit. You can manage your privacy settings
					within the app.
				</p>

				<h5>7. Children&apos;s Privacy</h5>

				<p>
					ApolloFit is not intended for children under the age of 13. We do not
					knowingly collect personal information from children.
				</p>

				<h5>8. Changes to This Policy</h5>

				<p>
					We may update this Privacy Policy from time to time. Any changes will
					be communicated through the app or via email.
				</p>

				<h5>9. Contact Us</h5>

				<p>
					If you have any questions or concerns about this Privacy Policy,
					please contact us at legal@apollofit.com.
				</p>

				<p>
					By using ApolloFit, you agree to the terms and conditions outlined in
					this Privacy Policy. Thank you for entrusting us with your information
					and being a part of our community.
				</p>
			</div>
		</div>
	);
}
