import { PayloadCallToActionBlock } from "../Payload";

interface CtaBlockProps {
	block: any;
}

export const CtaBlock = ({ block }: CtaBlockProps) => (
	<div className="cta-block">
		<PayloadCallToActionBlock {...block} key={`cta-block`} />
	</div>
);
