import useSWRMutation from "swr/mutation";

interface ForgotPasswordProps {
	arg: {
		email: string;
	};
}

const forgotPassword = async (_: string, { arg }: ForgotPasswordProps) => {
	const { email } = arg;
	const BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || "";

	const rawResponse = await fetch(`${BACKEND_URL}/users/forgot-password`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ email }),
	});
	const response = await rawResponse.json();

	return {
		code: response.code,
		message: response.message,
		data: response?.data,
	};
};

export const useForgotPassword = () => {
	return useSWRMutation("useForgotPassword", (_, arg) =>
		forgotPassword(_, arg)
	);
};
