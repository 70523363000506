import { ArchiveBlock, ContentBlock, CtaBlock, MediaBlock } from "../index";

interface FlexibleBlockProps {
	block: {
		__typename: string;
	} & any;
}

export const FlexibleBlock = ({ block }: FlexibleBlockProps) => (
	<>
		{block.__typename === "Archive" && <ArchiveBlock block={block} />}
		{block.__typename === "Cta" && <CtaBlock block={block} />}
		{block.__typename === "Content" && <ContentBlock block={block} />}
		{block.__typename === "MediaBlock" && <MediaBlock block={block} />}
	</>
);
