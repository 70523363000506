"use client";

import { Controller } from "react-hook-form";

import { InputTextProps } from "./InputText.props";
import classNames from "classnames";

export const InputText: React.FC<InputTextProps> = ({
	title,
	name,
	// children,
	defaultValue,
	errors,
	placeholder,
	password,
	caption,
	secondary = false,
	tertiary = false,
}) => {
	// if (children) {
	// 	return children;
	// }
	return (
		//@ts-ignore
		<Controller
			name={name}
			defaultValue={defaultValue}
			key={name}
			render={(form) => {
				return (
					<div
						className={classNames("input", {
							input__secondary: secondary,
							input__tertiary: tertiary,
						})}
					>
						<div className={"input__label"}>
							<label className={"input__title"}>
								<span>{title}</span>

								<span className={"input__error"}>
									{form.formState.errors?.[name!]?.message
										? (form.formState.errors?.[name!]?.message as JSX.Element)
										: ""}
								</span>
							</label>
						</div>
						<input
							{...form.field}
							onChange={(e) => form.field.onChange(e.target.value)}
							type={password ? "password" : "text"}
							placeholder={placeholder}
							value={form.field.value}
						/>
						{caption && <p className="input__caption">{caption}</p>}
					</div>
				);
			}}
		/>
	);
};
