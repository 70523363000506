"use client";

import { useLogin } from "hooks/authentication";
import { useDispatch } from "react-redux";
import { login } from "redux-store/reducers";
import { useState } from "react";
import { Form } from "../form";
import { Error } from "../Error";
import Yup from "../../utils/yupUtils";

export const LoginField = ({ toggle }: { toggle: () => void }) => {
	const { trigger: loginUser } = useLogin();
	const [error, setError] = useState<string | undefined>(undefined);

	const dispatch = useDispatch();

	const validationSchema = Yup.object({
		email: Yup.string().email("Invalid email address").required("Required"),
		password: Yup.string()
			.min(8, "Must be at least 8 characters")
			.required("Required"),
	});

	const initialValues = {
		email: "",
		password: "",
	};

	const onSubmit = (data: any) => {
		loginUser(data, {
			onSuccess: (_data) => {
				dispatch(
					login({
						user: _data.user,
						credentials: _data.token,
						profile: _data.user?.profile,
					})
				);
			},
			onError: (error) => {
				setError("You don't seem to have access. Are you in the right portal?");
				// setError(error.message);
			},
		});
	};

	return (
		<div className="container">
			<Form.Form
				buttonId="login-button"
				buttonText="Login"
				initialValues={initialValues}
				isLoading={false}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
				hasExternalSubmit
			>
				<Form.Input.Text name="email" title="Email" />
				<Form.Input.Text name="password" password title="Password" />
				<h5 onClick={toggle}>No account yet? Register here</h5>
			</Form.Form>

			{error ? (
				<Error>
					<p>{error}</p>
				</Error>
			) : null}
		</div>
	);
};
