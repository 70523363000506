"use client";
import { ForgotPasswordForm } from "./ForgotPasswordForm";

export default function ForgotPasswordPage() {
	return (
		<div className="page-content-centered">
			<h1>Forgot Password</h1>
			<ForgotPasswordForm />
		</div>
	);
}
