import {
	PayloadHighImpactHero,
	PayloadLowImpactHero,
	PayloadPostHero,
	PayloadProjectHero,
	PayloadMediumImpactHero,
} from "../Payload";

interface ContentBlockProps {
	hero: any;
	meta: any;
	post: any;
	title: string;
	categories: any[];
	createdAt: string;
}

export const Hero = ({ hero, post, meta, title }: ContentBlockProps & any) => (
	<div className="hero">
		{/* {hero.type === "highImpact" && <PayloadHighImpactHero {...hero} />}
		{hero.type === "lowImpact" && <PayloadLowImpactHero {...hero} />}
		{hero.type === "mediumImpact" && <PayloadMediumImpactHero {...hero} />} */}
		{post.title && <PayloadPostHero post={post} />}
	</div>
);
