import classNames from "classnames";
import Image from "next/image";
import { ReactNode } from "react";

interface UniqueSellingPointProps {
	title: string;
	description: string;
	image: string;
	side: string;
}

export const UniqueSellingPoint = ({
	title,
	description,
	image,
	side,
}: UniqueSellingPointProps) => (
	<div
		className={classNames("unique-selling-point", {
			"unique-selling-point--reverse": side === "right",
		})}
	>
		<div
			className={classNames("unique-selling-point__info-side", {
				"unique-selling-point__info-side--reverse": side === "right",
			})}
		>
			<div className="unique-selling-point__info-side__content">
				<div className="unique-selling-point__title">{title}</div>
				<div className="unique-selling-point__description">{description}</div>
			</div>
		</div>
		<div className="unique-selling-point__image-side">
			<div className="unique-selling-point__image">
				<Image src={image} alt={title} fill />
			</div>
		</div>
	</div>
);
