import { PayloadArchiveBlock, PayloadContentBlock } from "../Payload";

interface ArchiveBlockProps {
	block: any;
}

export const ArchiveBlock = ({ block }: ArchiveBlockProps) => (
	<div className="archive-block">
		<PayloadArchiveBlock {...block} key={`archive-block`} />
	</div>
);
