"use client";

export const PartnersComponent = () => (
	<div className="page-content">
		<h1>Partners</h1>
		If you are interested in partnering with us, please contact us at{" "}
		<a className="link" href="mailto:hello@apollofit.app">
			hello[at]apollofit.app
		</a>
	</div>
);
