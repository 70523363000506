import { LogoSVG } from "../assets/LogoSVG";
import { Size } from "../constants/enums/size";
import classnames from "classnames";

export const Logo = ({
	size,
	withText = false,
	textPosition = "horizontal",
}: {
	size: Size;
	withText?: boolean;
	textPosition?: "vertical" | "horizontal";
}) => {
	return (
		<div
			className={classnames(`logo`, {
				[`logo-${size}`]: true,
				"flex-col": textPosition === "vertical",
			})}
		>
			<LogoSVG />

			{withText && <div className="logo__text">Apollo</div>}
		</div>
	);
};
