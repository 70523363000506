"use client";

import { SettingsComponent } from "./SettingsComponent";

export default function SettingsPage() {
	return (
		<div className=" page-content-centered">
			<h1>Settings</h1>

			<SettingsComponent />
		</div>
	);
}
