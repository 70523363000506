"use client";
import Link from "next/link";
import { AppStore } from "@/components/icons/AppStore";
import { PlayStore } from "@/components/icons/PlayStore";
import { UniqueSellingPoints } from "@/components/unique-selling-points";
import { ArrowDown } from "react-feather";
import { useEffect, useRef } from "react";

export const Landing = () => {
	const uspRef = useRef<HTMLDivElement | null>(null);
	const onScrollPress = () => {
		if (uspRef.current) {
			uspRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		// Define the fbAsyncInit function
		// @ts-expect-error
		window.fbAsyncInit = function () {
			// @ts-expect-error
			FB.init({
				appId: "1786134982227545",
				cookie: true,
				xfbml: true,
				version: "v22.0",
			});

			// @ts-expect-error
			FB.AppEvents.logPageView();
		};

		const injectMetaPixel = () => {
			// Check if the fbq function already exists
			if ((window as any).fbq) return;

			// Create fbq function
			(function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
				if (f.fbq) return;
				n = f.fbq = function () {
					n.callMethod
						? n.callMethod.apply(n, arguments)
						: n.queue.push(arguments);
				};
				if (!f._fbq) f._fbq = n;
				n.push = n;
				n.loaded = true;
				n.version = "2.0";
				n.queue = [];
				t = b.createElement(e);
				t.async = true;
				t.src = v;
				s = b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t, s);
			})(
				window,
				document,
				"script",
				"https://connect.facebook.net/en_US/fbevents.js"
			);

			// Initialize Meta Pixel
			(window as any).fbq("init", "612600021144011");
			(window as any).fbq("track", "PageView");
		};

		// Load the Facebook SDK script
		const loadFacebookSDK = () => {
			const scriptId = "facebook-jssdk";
			if (document.getElementById(scriptId)) return;

			const js = document.createElement("script");
			js.id = scriptId;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			const fjs = document.getElementsByTagName("script")[0];
			fjs.parentNode?.insertBefore(js, fjs);
		};

		injectMetaPixel();
		loadFacebookSDK();
	}, []);

	return (
		<div className="">
			<div className="landing">
				<div className="landing__background" />

				<div className="landing__content">
					<div className="landing__title">
						<h1>Unlock your full potential</h1>
					</div>

					<div className="landing__subtitle">
						<h3>You don&#39;t have to do it alone</h3>
						<h3>So why would you?</h3>
					</div>
				</div>

				<div className="banners ">
					<div className="banner">
						<Link href="https://apps.apple.com/us/app/apollofit/id1668675980">
							<AppStore width={160} />
						</Link>
					</div>

					<div className="banner">
						<Link href="https://play.google.com/store/apps/details?id=com.apollofit.apollo&pcampaignid=web_share">
							<PlayStore width={160} />
						</Link>
					</div>

					<div className="landing__scroll" onClick={onScrollPress}>
						<ArrowDown color="#fff" />
					</div>
				</div>
			</div>
			<UniqueSellingPoints divRef={uspRef} />
		</div>
	);
};
