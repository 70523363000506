import { PayloadMediaBlock } from "../Payload";

interface MediaBlockProps {
	block: any;
}

export const MediaBlock = ({ block }: MediaBlockProps) => (
	<div className="media-block">
		<PayloadMediaBlock {...block} key={`media-block`} />
	</div>
);
