"use client";
import { useSearchParams } from "next/navigation";
import { ResetPasswordForm } from "./ResetPasswordForm";

export default function ResetPasswordPage() {
	const searchParams = useSearchParams();
	const token = searchParams.get("token");
	if (!token)
		return (
			<>
				<h1>No token</h1>
			</>
		);

	return (
		<div className="container">
			<h1>Forgot Password</h1>
			<ResetPasswordForm token={token} />
		</div>
	);
}
