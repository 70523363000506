"use client";

import { Card } from "@/components/ui/Card";

interface BlogsComponentProps {
	blogs: any[];
}

export const BlogsComponent = ({ blogs }: BlogsComponentProps) => (
	<div className="page-content">
		<h1>Blogs</h1>

		<div className="wrap">
			{blogs.map((blog) => (
				<Card
					key={blog.id}
					title={blog.title}
					description={blog.meta.description}
					image={{ url: blog.meta.image.url, alt: blog.meta.image.alt }}
					link={`/blogs/${blog.slug}`}
				/>
			))}
		</div>
	</div>
);
