import { Form as BaseForm } from "./form/Form";
import { InputDropdown } from "./input/InputDropdown/InputDropdown";
import { InputFile } from "./input/InputFile/InputFile";
import { InputText } from "./input/InputText/InputText";
import { InputNumber } from "./input/InputNumber/InputNumber";
import { InputDateTime } from "./input/InputDateTime/InputDateTime";
import { InputTextArea } from "./input/InputTextArea/InputTextArea";
import { InputCheckbox } from "./input/InputCheckbox/InputCheckbox";
import { InputTextHalf } from "./input/InputTextHalf/InputTextHalf";
import { InputSlider } from "./input/InputSlider/InputSlider";

export * from "./form/Form";
export * from "./input/InputFile/InputFile";
export * from "./input/InputTextHalf/InputTextHalf";
export * from "./input/InputText/InputText";
export * from "./input/InputNumber/InputNumber";
export * from "./input/InputCheckbox/InputCheckbox";
export * from "./input/InputDateTime/InputDateTime";
export * from "./input/InputTextArea/InputTextArea";

export const Form = {
	Form: BaseForm,
	Input: {
		Text: InputText,
		Number: InputNumber,
		TextHalf: InputTextHalf,
		Dropdown: InputDropdown,
		Slider: InputSlider,
		DateTime: InputDateTime,
		Checkbox: InputCheckbox,
		File: InputFile,
		TextArea: InputTextArea,
	},
};
