import React, { Fragment } from "react";
import Link from "next/link";

import { Gutter } from "../../_components/Gutter";
import { Media } from "../../_components/Media";
import RichText from "../../_components/RichText";
import { formatDateTime } from "../../_utilities/formatDateTime";

import classes from "./index.module.scss";
import { Post } from "@/types";

export const PostHero: React.FC<{
	post: Post & any;
}> = ({ post }) => {
	const {
		id,
		title,
		categories,
		meta: { image: metaImage, description } = {} as any,
		publishedAt,
		populatedAuthors,
	} = post;

	return (
		<Fragment>
			<Gutter className={classes.postHero}>
				<div className={classes.content}>
					<div className={classes.leader}>
						<div className={classes.categories}>
							{categories?.map((category, index) => {
								if (typeof category === "object" && category !== null) {
									const { title: categoryTitle } = category;

									const titleToUse = categoryTitle || "Untitled category";

									const isLast = index === categories.length - 1;

									return (
										<Fragment key={index}>
											{titleToUse}
											{!isLast && <Fragment>, &nbsp;</Fragment>}
										</Fragment>
									);
								}
								return null;
							})}
						</div>
					</div>
					<h1 className={classes.title}>{title}</h1>
					<p className={classes.meta}>
						{populatedAuthors && (
							<Fragment>
								{"By "}
								{populatedAuthors.map((author, index) => {
									const { name } = author;

									const isLast = index === populatedAuthors.length - 1;
									const secondToLast = index === populatedAuthors.length - 2;

									return (
										<Fragment key={index}>
											{name}
											{secondToLast && populatedAuthors.length > 2 && (
												<Fragment>, </Fragment>
											)}
											{secondToLast && populatedAuthors.length === 2 && (
												<Fragment> </Fragment>
											)}
											{!isLast && populatedAuthors.length > 1 && (
												<Fragment>and </Fragment>
											)}
										</Fragment>
									);
								})}
							</Fragment>
						)}
						{publishedAt && (
							<Fragment>
								{" on "}
								{formatDateTime(publishedAt)}
							</Fragment>
						)}
					</p>
				</div>
				<div className={classes.media}>
					<div className={classes.mediaWrapper}>
						{!metaImage && <div className={classes.placeholder}>No image</div>}
						{metaImage && typeof metaImage !== "string" && (
							<Media imgClassName={classes.image} resource={metaImage} fill />
						)}
					</div>
					{metaImage && typeof metaImage !== "string" && metaImage?.caption && (
						<RichText content={metaImage.caption} className={classes.caption} />
					)}
				</div>
			</Gutter>
		</Fragment>
	);
};
