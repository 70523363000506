import React from "react";
import { StaticImageData } from "next/image";

import { Page } from "@/types";
import { Gutter } from "../../_components/Gutter";
import { Media } from "../../_components/Media";
import RichText from "../../_components/RichText";

import classes from "./index.module.scss";

type Props = Extract<Page["layout"][0], { blockType: "mediaBlock" }> & {
	staticImage?: StaticImageData;
	id?: string;
};

export const MediaBlock: React.FC<Props> = (props) => {
	const { media, position = "default", staticImage } = props;

	let caption;
	if (media && typeof media === "object") caption = media.caption;

	return (
		<div className={classes.mediaBlock}>
			{position === "fullscreen" && (
				<div className={classes.fullscreen}>
					<Media resource={media} src={staticImage} />
				</div>
			)}
			{position === "default" && (
				<Gutter>
					<Media resource={media} src={staticImage} />
				</Gutter>
			)}
			{caption && (
				<Gutter className={classes.caption}>
					<RichText content={caption} />
				</Gutter>
			)}
		</div>
	);
};
