"use client";

import { FlexibleBlock } from "@/components/FlexibleBlock";
import { Hero } from "@/components/Hero";

interface FlexiblePage {
	layout: any[];
	hero: any;
	meta: any;
}

export const FlexiblePageComponent = ({
	authors,
	categories,
	hero,
	id,
	layout,
	meta,
	populatedAuthors,
	publishedAt,
	slug,
	title,
	type,
}: FlexiblePage & any) => (
	<div className="flexible-page">
		<Hero
			hero={hero}
			meta={meta}
			post={{
				authors,
				categories,
				id,
				publishedAt,
				populatedAuthors,
				slug,
				meta,
				title,
				type,
			}}
		/>

		<div className="flexible-page__content">
			{layout?.map((block, index) => (
				<FlexibleBlock key={`${block.slug}-${index}`} block={block} />
			))}
		</div>
	</div>
);
